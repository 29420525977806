// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	serverUrl: 'https://livi-api-c5031d0b72bc.herokuapp.com',
	production: false,
	govmapToken: '0a7c23b9-25fa-45eb-9cff-8a6236165950'
};
