import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyComponent } from './survey.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureModalComponent } from './components/signature-modal/signature-modal.component';

@NgModule({
	declarations: [SurveyComponent, SignatureModalComponent],
	imports: [
		CommonModule,
		HttpClientModule,
		RouterModule,
		TranslateModule,
		SignaturePadModule
	]
})
export class SurveyModule {
}
