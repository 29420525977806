import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

	subscription: Subscription;

	constructor(private router: Router, private translateService: TranslateService) {
		this.translateService.use('he');
	}

	ngOnInit() {
		this.subscription = this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd)
			)
			.subscribe(() => window.scrollTo(0, 0));
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
