<p>קיבלנו את התשובה שלך, רק תאשר לנו שזוהי ההחלטה הסופית שלך</p>

<div class="signature-container">
    <p>חתימה:</p>
    <signature-pad #signaturePad [options]="signaturePadOptions" (onBeginEvent)="drawStart()">
    </signature-pad>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">ביטול</button>
    <button type="button" class="btn btn-outline-dark" (click)="approveVote()">אשר</button>
</div>