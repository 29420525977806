<i class="home-button" [routerLink]="'/'">לדף הבית</i>

<div class="livi-title" [routerLink]="'/'">
    <i class="logo icon-logo-o"></i>
    <span class="title">{{'LOGIN.TITLE' | translate}}</span>
</div>

<div *ngIf="userMessage && isValidUrl" class="message">
    <span class="message-title">{{userMessage?.message.title}}</span>
    <span class="message-author" *ngIf="userMessage?.message.author">{{getFullName(userMessage?.message.author)}} -
        {{userMessage?.message.author.role | translate}}</span>
    <div class="date-container">
        <span *ngIf="userMessage?.message.creationDate">נפתח
            ב-</span>
        <span
                class="date">{{(userMessage?.readTime ? userMessage?.readTime : userMessage?.creationTime) | date:'dd/MM/yyyy HH:mm'}}</span>
    </div>
    <div class="message-content" [innerHTML]="userMessage?.message.content"></div>
    <div class="attached-files">
        <div *ngFor="let file of userMessage?.message?.files">
            <a class="file" *ngIf="file" [href]="file.filePath"
               [download]="file.fileName">
                <div class="file-type">
                    <i class="file-icon fa {{filesService.getFileIcon(file.fileName) ? 'fa-file-' + filesService.getFileIcon(file.fileName) + '-o': 'fa-file-o'}}"
                       [style.color]="filesService.getFileColor(file.fileName)"></i>
                </div>
                <span>{{file.fileName}}</span>
                <i class="download ft ft-download"></i>
            </a>
        </div>
    </div>
</div>
