import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss']
})
export class SignatureModalComponent implements OnInit, AfterViewInit {
  @ViewChild('signaturePad') signaturePad: SignaturePad;

  signed = false;
  signaturePadOptions: Object = {
    minWidth: 3,
    canvasWidth: 350,
    canvasHeight: 170
  };

  constructor(public modal: NgbActiveModal, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.signaturePad.set('minWidth', 5);
    this.signaturePad.clear();
  }

  drawStart() {
    this.signed = true;
  }

  approveVote() {
    if (!this.signaturePad.isEmpty()) {
      this.modal.close(this.signaturePad.toDataURL());
    } else {
      this.toastr.warning('יש לחתום לפני שליחת ההצבעה', 'דרושה חתימה');
    }
  }
}
