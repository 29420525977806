<i class="home-button" [routerLink]="'/'">לדף הבית</i>

<div class="livi-title" [routerLink]="'/'">
    <i class="logo icon-logo-o"></i>
    <span class="title">{{'LOGIN.TITLE' | translate}}</span>
</div>

<div class="survey-title"></div>

<div class="survey" *ngIf="userSurvey">
    <div>
        <h4 class="survey-title title">{{userSurvey?.survey.title}}</h4>
        <span class="survey-author" *ngIf="userSurvey?.survey.creator">{{getFullName(userSurvey?.survey.creator)}} -
            {{userSurvey?.survey.creator.role | translate}},
            {{(userSurvey?.projectUser.isRepresentative ? 'TENANTS.REPRESENTATIVE' : '') | translate}}</span>
        <!--        <div class="date-container">-->
        <!--            <span *ngIf="userSurvey?.survey.seenAt">{{'SURVEYS.START_DATE' | translate}}</span>-->
        <!--            <span class="date">{{(userSurvey?.survey.seenAt ? userSurvey?.survey.seenAt: userSurvey?.survey.creationDate) | date:'dd/MM/yyyy HH:mm'}}</span>-->
        <!--        </div>-->
    </div>
    <div>
        <div class="survey-content" [innerHTML]="userSurvey?.survey.content"></div>
    </div>
    <div class="survey-footer">
        <div class="survey-more-details">
            <div>
                <label>{{'SURVEYS.ANSWERED_BY' | translate}}:</label>
                <span>{{userSurvey?.survey?.result?.usersCanVote}} / {{ userSurvey?.survey?.result?.approvesSum + userSurvey?.survey?.result?.declinesCounter }}
                </span>
                <!-- {{userSurvey?.survey.result.approvesCounter +
            userSurvey?.survey.result.declinesCounter}}/{{userSurvey?.survey.result.approvesCounter +
            userSurvey?.survey.result.declinesCounter + userSurvey?.survey.result.pendingCounter}} -->
            </div>
            <div>
                <label>{{'SURVEYS.FINISH_DATE' | translate}}:</label>
                {{userSurvey?.survey.endDate | date:'dd/MM/yyyy HH:mm'}}
            </div>
            <div class="attached-files">
                <div *ngFor="let file of userSurvey?.survey?.files">
                    <a (click)="didDownloadFile = true" class="file" *ngIf="file" [href]="file.filePath"
                        [download]="file.fileName">
                        <div class="file-type">
                            <i class="file-icon fa {{filesService.getFileIcon(file.fileName) ? 'fa-file-' + filesService.getFileIcon(file.fileName) + '-o': 'fa-file-o'}}"
                                [style.color]="filesService.getFileColor(file.fileName)"></i>
                        </div>
                        <span>{{file.fileName}}</span>
                        <i class="download ft ft-download"></i>
                    </a>
                </div>

            </div>
        </div>
    </div>
    <div class="explanation" *ngIf="permissionsService.canVote(userSurvey?.projectUser)">
        <i class="ft ft-info"></i>
        {{'SURVEYS.EXPLANATION' | translate}}
    </div>
    <div class="explanation" *ngIf="userSurvey?.vote === true || userSurvey?.vote === false">
        <i class="ft ft-info"></i>
        {{'SURVEYS.ALREADY_ANSWERED' | translate}}
    </div>
</div>

<div class="vote-buttons" *ngIf="permissionsService.canVote(userSurvey?.projectUser)">
    <button (click)="vote(false)" class="btn"
        [ngClass]="{active: userSurvey?.vote === false}">{{userSurvey?.survey.declineText}}</button>
    <button (click)="vote(true)" class="btn"
        [ngClass]="{active: userSurvey?.vote === true}">{{userSurvey?.survey.approveText}}</button>
</div>
