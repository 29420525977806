import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { LayoutModule } from '@angular/cdk/layout';
import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule,
	PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from './core/services/auth-interceptor.service';
import { SentryErrorHandler } from './shared/services/sentry-error-handler.service';
import { AuthService } from './core/services/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { MessageModule } from './message/message.module';
import { SurveyModule } from './survey/survey.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GlobalErrorHandler } from './shared/services/global-error.handler';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		MessageModule,
		SurveyModule,
		NgxSpinnerModule,
		ToastrModule.forRoot({ positionClass: 'toast-top-left' }),
		NgbModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		MatGoogleMapsAutocompleteModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyCXEfdVvZaWW5gptd74KgJ6Q2ImWV6K4eg',
			libraries: ['places'],
			language: 'iw',
		}),
		PerfectScrollbarModule,
	],
	providers: [
		AuthService,
		AuthGuard,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		{ provide: ErrorHandler, useClass: SentryErrorHandler },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
