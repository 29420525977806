import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IResponse } from 'app/shared/models/response.interface';
import { UserSurvey } from 'app/shared/models/survey.interface';
import { FilesService } from 'app/shared/services/files.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SignatureModalComponent } from './components/signature-modal/signature-modal.component';
import { PermissionsService } from 'app/shared/services/permissions.service';

@Component({
	selector: 'app-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, AfterViewInit {
	surveyToken: string;
	userSurvey: UserSurvey;
	didDownloadFile = true;

	constructor(
		private http: HttpClient,
		public filesService: FilesService,
		public permissionsService: PermissionsService,
		private activeRoute: ActivatedRoute,
		private toastr: ToastrService,
		private modal: NgbModal,
		private router: Router
	) {
		this.activeRoute.params.subscribe((params) => {
			this.surveyToken = params['token'];

			if (this.surveyToken) {
				this.http
					.get<IResponse<UserSurvey>>(
						`${environment.serverUrl}/survey/${this.surveyToken}`
					)
					.subscribe((response) => {
						this.userSurvey = response.data;

						if (!this.userSurvey) {
							this.router.navigate(['/auth/error']);
						}
					});
			} else {
				this.router.navigate(['/auth/error']);
			}
		});
	}

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		if (this.userSurvey?.survey?.file) {
			this.didDownloadFile = false;
		}
	}

	getFullName(entity: { firstName; lastName }) {
		return `${entity.firstName} ${entity.lastName}`;
	}

	vote(vote: boolean) {
		if (this.didDownloadFile) {
			if (this.userSurvey.survey.shouldSign) {
				this.modal.open(SignatureModalComponent).result.then((signature) => {
					if (signature) {
						this.sendVote(vote, signature);
					} else {
						this.toastr.error('יש לחתום על הטופס', 'הזנה לא תקינה');
					}
				});
			} else {
				this.sendVote(vote);
			}
		} else {
			this.toastr.warning(
				'יש להוריד את הקובץ המצורף ולעיין בו תחילה',
				'הזנה לא תקינה'
			);
		}
	}

	sendVote(vote: boolean, signature?: string) {
		this.http
			.post<IResponse<any>>(
				`${environment.serverUrl}/survey/${this.surveyToken}`,
				{
					vote,
					signature,
				}
			)
			.subscribe((response) => {
				if (response.success) {
					this.userSurvey.vote = vote;
					this.toastr.success(
						'הצבעתך נרשמה בהצלחה, מיד תועבר חזרה למערכת',
						'הצלחה'
					);

					setTimeout(() => {
						this.router.navigate([
							'/projects/',
							this.userSurvey.projectUser.project.id,
							'surveys',
						]);
					}, 3000);
				} else {
					this.toastr.error('לא הצלחנו לקבל את הצבעתך', 'שגיאה');
				}
			});
	}
}
