import { Injectable } from '@angular/core';
import { ProjectUser } from '../models/tenant.interface';
import { EProjectUserType, ERole, EUserType } from '../models/enums';

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
	constructor() {
	}

	canEditProject(projectUser: ProjectUser) {
		if (projectUser) {
			return (projectUser.type === EProjectUserType.HasRole && projectUser.role === ERole.ProjectManager) ||
				projectUser.user.type === EUserType.Admin;
		}

		return false;
	}

	canManageFiles(projectUser: ProjectUser) {
		if (projectUser) {
			return projectUser.canManageFiles || projectUser.user.type === EUserType.Admin;
		}

		return false;
	}

	canManageSurveys(projectUser: ProjectUser) {
		if (projectUser) {
			return projectUser.canManageSurvey || projectUser.user.type === EUserType.Admin;
		}

		return false;
	}

	canManageMessages(projectUser: ProjectUser) {
		if (projectUser) {
			return projectUser.canManageMessages || projectUser.user.type === EUserType.Admin;
		}

		return false;
	}

	canAddTenants(projectUser: ProjectUser) {
		if (projectUser) {
			return (projectUser.canManageProjectUsers || projectUser.user.type === EUserType.Admin);
		}

		return false;
	}

	canDeleteProjects(projectUser: ProjectUser) {
		if (projectUser) {
			return projectUser.user.type === 'Admin';
		}

		return false;
	}

	canEditTenant(projectUser: ProjectUser) {
		if (projectUser) {
			return (projectUser.canManageProjectUsers || projectUser.user.type === EUserType.Admin);
		}

		return false;
	}

	canViewMessageStatistics(projectUser: ProjectUser) {
		if (projectUser) {
			return (projectUser.canManageMessages || projectUser.user.type === EUserType.Admin || projectUser.type === EProjectUserType.HasRole);
		}

		return false;
	}

	canVote(projectUser: ProjectUser) {
		if (projectUser) {
			return projectUser?.subParcels?.some(subParcel => subParcel.canVote) && projectUser?.type === EProjectUserType.Tenant;
		}

		return false;
	}
	isLawyerOrProjectManager(projectUser: ProjectUser): boolean {
		if (projectUser) {
			return projectUser.role === 'Lawyer' || projectUser.role === 'ProjectManager' ||
				projectUser.role === 'EntrepreneurLawyer';
		}
		return false;
	}
}
