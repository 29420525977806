import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MessageComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    TranslateModule
  ]
})
export class MessageModule { }
