import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponse } from 'app/shared/models/response.interface';
import { UserMessage } from 'app/shared/models/user-message.interface';
import { environment } from 'environments/environment';
import { FilesService } from 'app/shared/services/files.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  messageToken: string;
  userMessage: UserMessage;
  isValidUrl = true;

  constructor(
    private http: HttpClient,
    public filesService: FilesService,
    private activeRoute: ActivatedRoute,
    private router: Router) {
    this.activeRoute.params.subscribe(params => {
      this.messageToken = params['token'];

      if (this.messageToken) {
        this.http.get<IResponse<UserMessage>>(`${environment.serverUrl}/message/${this.messageToken}`).subscribe(response => {
          this.userMessage = response.data;

          if (!this.userMessage) {
            this.isValidUrl = false;
            this.router.navigate(['/auth/error']);
          }
        });
      } else {
        this.isValidUrl = false;
        this.router.navigate(['/auth/error']);
      }
    });
  }

  ngOnInit(): void {
  }

  getFullName(entity: { firstName, lastName }) {
    return `${entity.firstName} ${entity.lastName}`;
  }
}

