export const imagesExtensions = ['jpg', 'jpeg', 'svg', 'png', 'gif', 'tiff'];

export enum EUserType {
	Admin = 'Admin',
	Regular = 'Regular'
}

export enum EProjectUserType {
	Tenant = 'Tenant',
	HasRole = 'HasRole'
}

export enum ERole {
	Lawyer = 'Lawyer',
	Architect = 'Architect',
	Administration = 'Administration',
	ProjectManager = 'ProjectManager',
	Referent = 'Referent',
	Supervisor = 'Supervisor',
	PublicHousingContact = 'PublicHousingContact',
	Initiator = 'Initiator',
	LocalAuthority = 'LocalAuthority',
	Hakal = 'Hakal',
	Appraisal = 'Appraisal',
	EntrepreneurLawyer = 'EntrepreneurLawyer',
}

export enum EUserStatus {
	Pending = 'Pending',
	Approved = 'Approved'
}

export enum EOwnerType {
	Owner = 'Owner',
	PublicHousing = 'PublicHousing',
	Other = 'Other'
}

export enum EAccessLevel {
	Messages = 'Messages',
	Surveys = 'Surveys',
	Files = 'Files',
}

export enum SurveyStatus {
	Active = 'Active',
	Inactive = 'Inactive'
}

export enum PropertyType {
	Apartment = 'Apartment',
	Store = 'Store',
	Trade = 'Trade',
	Warehouse = 'Warehouse',
	Other = 'Other'
}

export const HebrewOwnerType = {
	בעלים: EOwnerType.Owner,
	'דיור ציבורי': EOwnerType.PublicHousing,
	אחר: EOwnerType.Other,
};

export const HebrewPropertyType = {
	דירה: PropertyType.Apartment,
	חנות: PropertyType.Store,
	מסחר: PropertyType.Trade,
	מחסן: PropertyType.Warehouse,
	אחר: PropertyType.Other,
};

export const HebrewProjectUserType = {
	דייר: EProjectUserType.Tenant,
	'נותן שירות': EProjectUserType.HasRole,
};

export const HebrewLanguage = {
	עברית: 'he',
	רוסית: 'ru',
	ערבית: 'ar',
	אנגלית: 'en',
	המהרית: 'am',
	אחר: 'other',
};
