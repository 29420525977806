import { Injectable } from '@angular/core';
import { Folder } from '../models/folder.interface';
import { of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IResponse } from '../models/response.interface';
import { map, tap } from 'rxjs/operators';
import { IFile } from '../models/file.interface';
import { remove as _remove } from 'lodash';

const SERVER_URL = environment.serverUrl;
const projectEndpoint = projectId => `${SERVER_URL}/project/${projectId}/files`;

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  folders$ = new BehaviorSubject<Folder[]>([]);

  fileTypesColors = {
    pdf: '#F44336',
    doc: '#2A5699',
    docx: '#2A5699',
    xls: '#227446',
    xlsx: '#227446'
  };

  fileTypesIcons = {
    pdf: 'pdf',
    doc: 'word',
    docx: 'word',
    xls: 'excel',
    xlsx: 'excel'
  };

  constructor(private http: HttpClient) { }

  createFolder(result: any, projectId: number) {
    return this.http.post<IResponse<Folder[]>>(`${projectEndpoint(projectId)}`, {
      fileName: result.folderName
    }).pipe(tap((response) => {
      const folders = this.folders$.value;
      folders.push(response.data[0]);

      this.folders$.next(folders);
    })).pipe(map(res => res.data));
  }

  createFile(files: FileList, parent: number, projectId: number) {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      console.log(files.item(i).name);
      formData.append('file[]', files.item(i), encodeURIComponent(files.item(i).name));
    }

    formData.append('parent', parent.toString());

    return this.http.post<IResponse<IFile[]>>(`${projectEndpoint(projectId)}`, formData).pipe(tap((response) => {
      const folders = this.folders$.value;
      const parentFolder = folders.find(folder => folder.id === parent);

      parentFolder.children = parentFolder.children || [];

      parentFolder.children.push(...response.data);
      this.folders$.next(folders);
    }));
  }

  fetchFolders(projectId: number) {
    return this.http.get<IResponse<Folder[]>>(`${projectEndpoint(projectId)}`).pipe(
      map(response => response.data),
      tap(folders => {
        this.folders$.next(folders);
      }));
  }

  editFileName(projectId, fileId: number, fileName: string) {
    return this.http.put<IResponse<IFile>>(`${projectEndpoint(projectId)}/${fileId}`, { fileName }).pipe(
      map(response => response.data),
      tap(updatedFile => {
        const folders = this.folders$.value;
        folders.forEach(folder => {
          folder.children.forEach(currentFile => {
            if (currentFile.id === fileId) {
              currentFile.fileName = updatedFile.fileName;
            }
          })
        });

        this.folders$.next(folders);
      })
    );
  }

  removeFile(projectId, fileId: number) {
    return this.http.delete<IResponse<IFile[]>>(`${projectEndpoint(projectId)}/${fileId}`).pipe(
      map(response => response.data),
      tap(removedFiles => {
        const folders = this.folders$.value;

        _remove(folders, folder => folder.id === fileId);

        folders.forEach(folder => {
          _remove(folder.children, childFile => childFile.id === fileId);
        });

        this.folders$.next(folders);
      })
    );
  }

  getAllFiles() {
    return this.folders$.pipe(map((folders: Folder[]) => {
      return folders.reduce((p, folder) => p.concat(folder.children), []);
    }));
  }

  toggleFileIsFavorite(projectId: number, fileId: number, isFavorite: boolean) {
    return this.http.put<IResponse<Folder[]>>(`${projectEndpoint(projectId)}`, { fileId, isFavorite });
  }

  extractFileExtension(fileName: string) {
    return fileName?.split('.').pop();
  }

  getFileIcon(fileName: string) {
    const extension = this.extractFileExtension(fileName);

    return this.fileTypesIcons[extension];
  }

  getFileColor(fileName: string) {
    const extension = this.extractFileExtension(fileName);

    return this.fileTypesColors[extension];
  }

  getFavoriteFiles() {
    return this.folders$.pipe(map(folders => {
      return this.extractFavoriteFiles(folders);
    }))
  }

  extractFavoriteFiles(folders) {
    if (folders && folders.length) {
      return folders
        .reduce((p, n) => p.concat((n.children || [])
          .filter(f => f.isFavorite)), []);
    } else {
      return [];
    }
  }
}
