import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthService, LOCAL_STORAGE_TOKEN } from './auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(private router: Router, private toastr: ToastrService,
	            private spinner: NgxSpinnerService,
	            private authService: AuthService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.authService.tokenSubject
			.pipe(
				take(1),
				switchMap(token => {
					token = token || localStorage.getItem(LOCAL_STORAGE_TOKEN);

					if (token) {
						// add authorization header to request
						req = req.clone({
							setHeaders: {
								Authorization: `JWT ${token}`
							}
						});
					}

					return next.handle(req).pipe(
						catchError((err, caught) => {
							this.handleError(err);
							return of(err);
						}));
				})
			);
	}

	private handleError(err: any) {
		this.spinner.hide();

		if (err.status === 401) {
			this.authService.logout();
			this.router.navigate(['/', 'auth', 'login']);
			throw err;
		} else if (err.status === 403) {
			this.toastr.error('נראה שאין לך הרשאה מספקת', 'אופס!');
			this.router.navigate(['/', 'auth', 'login']);
		} else {
			if (err.error?.error?.errInUse) {
				this.toastr.error('לא ניתן למחיקה בגלל שימוש במקום אחר', 'אופס!');
			} else if (err.error?.error?.logMessage) {
				this.toastr.error(err.error?.error?.logMessage, 'שגיאה');
			} else {
				this.toastr.error('משהו לא הצליח', 'אופס!');
			}
		}
	}
}
