import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { MessageComponent } from './message/message.component';
import { SurveyComponent } from './survey/survey.component';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './core/core.module#CoreModule'
  },
  { path: '', pathMatch: 'full', redirectTo: '/projects', canActivate: [AuthGuard] },
  { path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule), canActivate: [AuthGuard] },
  { path: 'areas', loadChildren: () => import('./areas/areas.module').then(m => m.AreasModule), canActivate: [AuthGuard] },
  { path: 'taba', loadChildren: () => import('./taba/taba.module').then(m => m.TabaModule), canActivate: [AuthGuard] },
  { path: 'message/:token', component: MessageComponent },
  { path: 'survey/:token', component: SurveyComponent },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
